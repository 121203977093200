// pages
// master array object of scripts specific to each individual page.
// one for each page in the site.
// keys = pageID
//

/* jshint sub:true */ // This option suppresses warnings about using [] notation when it can be expressed in dot notation
/* globals ImagesLoaded, SidePanelCollapse, site */ // global variables that are not formally defined in the source code
/* jshint latedef: false */ // This option prohibits the use of a variable before it was defined

const projectPortfolioData = require("portfolio/site-portfolio-canonical");

// some default value for breakpoint in case css variables are not working/available
const default_site_breakpoint_md = 768;
// determine the breakpoint_md value
// cache it for the site/
// this is used in checkWidth().
// // bootstrap values are included as css variables in bootstrap.css.
const breakpoint_md = parseInt(getComputedStyle(document.body).getPropertyValue("--breakpoint-md"), 10);
const site_breakpoint_md = typeof breakpoint_md === "undefined" ? breakpoint_md : default_site_breakpoint_md;

// set of internal utility methods
const util = {

    // get width of the current window
    checkWidth: () => window.innerWidth > site_breakpoint_md,

    scrollSpyCreate: function scrollSpyCreate(target, shouldSpy) {
        // if a target value is passed, use that; if not, use the default value for the primary nav
        target = target ? target : "#primaryNav";
        // widthFlag to check for window width and only activate scrollspy if true = in certain cases, wider window sizes
        // default to the computed active flag, whatever that is
        shouldSpy = shouldSpy !== undefined ? shouldSpy : scrollSpyActivateFlag;

        if (shouldSpy) {
            $(document.body).scrollspy({
                target: target,
                offset: 100,
            });
        }
    },

    scrollSpyRefresh: () => {
        $(document.body).scrollspy("refresh");
    },

    scrollSpyDispose: () => {
        $(document.body).scrollspy("dispose");
    },

    scrollSpyToggle: function (target) {
        util.scrollSpyDispose();
        util.scrollSpyCreate(target);
    },

    // for project content pages with a table of contents, run a special event handler to apply a special class to style
    // the main active heading when the subsection is active
    //    scrollSpyTOCEvent: function(target) {
    //
    //         var topSelector = target + " .active";
    //         var fancyNavSetup = function(target) {
    //             return function fancyNav() {
    //             };
    //         };
    //
    //         // requires jquery eventhandling because ... apparently the event only occurs in jquery space...?
    //         $(window).on("activate.bs.scrollspy", function(e) {
    //           // do something…
    //           console.log("scrollspy event", e);
    //         });
    //
    //    },

    // utility function: get the page data for the @param page
    pageData: (page) => projectPortfolioData[page],

    // utility function: generate full path to a project page
    getProjectURL: function getProjectURL(rsc) {
        const pathProjects = site.siteClientSideData["path-projects"];
        return window.siteRoot + pathProjects + rsc;
    },

    // utility function: get values for the next and project pages for navigatin'
    getNextAndPreviousProjects: function (ID) {
        let nextPrevious;

        if (projectPortfolioData[ID] !== undefined) {
            const nextID = projectPortfolioData[ID].next.id;
            const previousID = projectPortfolioData[ID].previous.id;

            nextPrevious = {
                next: {
                    id: nextID,
                    url: projectPortfolioData[nextID].url,
                },
                previous: {
                    id: previousID,
                    url: projectPortfolioData[previousID].url,
                },
            };
        } else {
            console.log("Er...a page is either NOT there or is undefined.");
            nextPrevious = false;
        }

        return nextPrevious;
    },

    // set up a function to get the computed style of the @param element
    getIfDisplayed: function getDisplayStyle(el) {
        return () => (getComputedStyle(el).display !== "none" ? true : false);
    },
};

// flag to only activate scrollspy if window is larger than breakpoint
// this is dependent on the layout being in columns are md size, so that nav will be displayed
const scrollSpyActivateFlag = util.checkWidth();

// options for the sidepanel in the site
const SidePanelOptions = {
    // sidepanelElement: "#sidePanel",
    // sidepanelCloseElement: ".sidePanel-close",
    durationShow: "0.67s",
    // durationHide: "0.34s",
    durationHideFast: "0.11s",
    // sidePanelIsOpenClass: "sidePanel-open",
    // backdrop: true,
    // backdropStyleClass: "light",
    // handleLinks: false,
};

// utility function
// instantiate a new sidePanel for the page for when/if the sidenav will display
const sideNavigation = () => {
    window.SidePanel = new SidePanelCollapse(SidePanelOptions);
};


// PAGES
// define page-specific scripts, if any are needed.
// if a page needs specific procession, create a method keyed to the pageID name
// if not, can use the defaultPage

const pageMethods = {

    // pages script for homepage = index.html
    homepage: function homepage(pageID) {
        // start page setup

        // define the 2 different nav's for the page
        // for scrollspy, these need to be css selectors for the <nav> element
        const navHorizontal = "#primaryNav";
        const navVertical = "#sidePanelNav";

        // BEGIN: page specific functions

        const emailScramble = require("email-scramble");
        const tinkererPopup = require("modules/site-tinkerer.js");
        const portfolioGallery = require("site-portfolio-gallery.js");

        // function that is executed when the phone # is clicked (event --> click).
        // phone number is encoded in data attribute of the link, so that the href can contain a message,
        // and also so that the actual phone number is not available for search engines to find.
        // the function
        // unscrambles #, and puts the unscrambled into the page
        // so that the correct # will be called (if the technology supports it)
        const phoneMe = function (e) {
            const phoneLink = this;
            // note: data reference changes from "phone-number" to camel-case "phoneNumber"
            const phoneNumber = phoneLink.dataset.phoneNumber;
            phoneLink.href = emailScramble.decode(phoneNumber);
        };

        // ad-hoc function for the homepage/index page to add analytic interaction tracking to the 'tinkerer' popover
        // so that when it is invoked, it logs event
        const trackingCategory = "popup";
        const trackingAction = "shown";
        const trackingName = "Tinkerer popup";
        const trackPopover = () => {
            site.analytics.trackEvent(trackingCategory, trackingAction, trackingName);
        };

        // once the fadein animation is finished, turn off the visibility of the white layer entirely
        // in hope that performance is ever so slightly better
        // april.2018: settings display:none causes a 1px shift left of the heading text. not sure why this is;

        function whenFadeEnds(e) {
            e.target.classList.replace("animated", "animationDone");
        }

        // handle toggling the nav & scrollspy
        function navDisplayed(b) {
            if (b) {
                // TRUE = now the horiz nav IS displayed
                // so dispose of the existing scrollspy, and create a new one for the primarynav
                util.scrollSpyToggle(navHorizontal);
                // console.log ("toggle: now display " + navHorizontal);
            } else {
                // FALSE =now the horiz nav IS NOT displayed
                // so dispose of the existing scrollspy, and create a new one for sidenav
                util.scrollSpyToggle(navVertical);
                // console.log ("toggle: now display " + navVertical);
            }
        }

        // END: page specific functions

        // deal with the 2 possible primary navs on the page:
        // the horizontal, primarynav, displayed at wider sizes
        // the vertical, sidenav, displayed when the primary nav is collapsed at smaller screen sizes

        // start by checking
        // the primarynav horizontal element that will collapse according to screen size (css media query)
        const hnav = document.getElementById("primaryNav-horiz");
        // function: is the horizontal nav displayed (have a display value other than 'none')
        const horizNavDisplayed = util.getIfDisplayed(hnav);

        // create the scrollspy on the nav for the current state of the homepage
        // homepage: needs to have the scrollspy work on both
        // (a) the primarynav, horiztonal links when window is wider,
        // (2) the sidenav, vertical list when window is narrower

        const targetNav = horizNavDisplayed() ? navHorizontal : navVertical;
        // create the first scrollSpy on the currently displayed nav, and pass true because it "should spy" now.
        util.scrollSpyCreate(targetNav, true);

        // now,
        // initialize boolean value for determining if the nav display toggled between display states,
        // meaning the the nav changed from horiz to vertical
        let previousNavWasHoriz = horizNavDisplayed();

        // set up so that resize events will update the primaryNav behavior element
        window.addEventListener(
            "resize",
            () => {
                // check the current style.display value

                const hnavDisplayState = horizNavDisplayed();
                if (hnavDisplayState && !previousNavWasHoriz) {
                    // if horiz nav IS displayed && toggled is false, then
                    // signal that norizontal nav is true and nav is displayed
                    navDisplayed(true);
                    previousNavWasHoriz = true;
                } else if (!hnavDisplayState && previousNavWasHoriz) {
                    // else if horiz nav is NOT displayed and toggles is true
                    // signal that norizontal nav is false
                    navDisplayed(false);
                    previousNavWasHoriz = false;
                }
            },
            false,
        );

        // set up the tinkerer popup
        tinkererPopup.main();

        // set up the analytic tracking for the popup
        const popup = document.getElementById("tinkererPopover");
        popup.addEventListener("mouseenter", trackPopover, false);

        // handle the scrmbled phone #
        const phoneLink = document.getElementById("phone-scramble");
        phoneLink.addEventListener("click", phoneMe, { once: true }, false);

        // attempt at page optimization:
        // set up eventlistener for the method when home page background fades
        const fadeElement = document.getElementsByClassName("backcell")[0];
        fadeElement.addEventListener("animationend", whenFadeEnds, { once: true, passive: true }, false);

        // setup the portfolio card behavior
        // make the grid, hovering, etc
        portfolioGallery();

        // initialize the sidebar (for when it may be displayed)
        sideNavigation();

        // end of page setup
        util.scrollSpyRefresh();
    },

    "alpine-sierra": function alpineSierra(pageID) {
        // BEGIN: page specific inclusions
        window.pageData = util.pageData(pageID);
        const projectIcons = require("projects/alpine/sierra-icons");
        // release the icon grid!
        projectIcons.main();

        // invoke default stuff
        this.default(pageID);

    },

    "alpine-iconography": function alpineIconography(pageID) {
        // BEGIN: page specific inclusions
        // const projectIconography = require("projects/alpine/iconography");
        // END: page specific inclusions

        // release the icon grid!
        // projectIconography.main();

        // invoke default stuff
        this.default(pageID);
    },

    // "alpine-dashboard": util.defaultPage,

    // "alpine-worklet": util.defaultPage,

    "alpine-autosave": function alpineAutosave(pageID) {
        const setupIsotope = (gridSelector, gridItemSelector) => {
            // options
            const isotopeOptions = {
                itemSelector: gridItemSelector,
                layoutMode: "packery",
                percentPosition: true,
                initLayout: false,
            };

            const iso = new Isotope(gridSelector, isotopeOptions);
            ImagesLoaded(gridSelector, () => {
                // loaded. now call layout
                iso.layout();
            });
        };

        const gridSelector = "#grid-autosave";
        const itemSelector = ".grid-item"; // class name of each element
        setupIsotope(gridSelector, itemSelector);

        // invoke default stuff
        this.default(pageID);
    },

    // "alpine-notebooks": util.defaultPage,

    // "ca-executiveInsight": util.defaultPage,

    // "ca-apmMobile": util.defaultPage,

    // "ca-mobile": util.defaultPage,

    // "ca-gmRedesign": util.defaultPage,

    // "ca-gmMobile": util.defaultPage,

    // "evernote-sharing": util.defaultPage,

    // "collabnet-projectMetrics": util.defaultPage,

    // "collabnet-rubicon": util.defaultPage,

    // "collabnet-tasks": util.defaultPage,

    // "collabnet-ptRedesign": util.defaultPage,

    // "paypal-merchantManager": util.defaultPage,

    // "verso-ingram": util.defaultPage,

    // "about-site": util.defaultPage,

    // special pages
    // "crossproject-visual": util.defaultPage,

    // test pages
    // "page-pagevalues": util.default,
    // "page-pagetest": util.defaultPage,

    // the default method that all pages need/use
    default: (pageID) => {
        sideNavigation();
        // activate scrollspy on the page's table of contents
        util.scrollSpyCreate("#tableOfContents", scrollSpyActivateFlag);
    },
};




// *****
// page function
//
// call page specific function(s)

// const pageMain = (pageID) => {
//     // first, any functions needed for the page
//     if (typeof site.pageMethods[pageID] !== "undefined") {
//         // there is page function defined, so invoke that
//         site.pageMethods[pageID](pageID);
//     } else {
//         // no page function defined, so invoke the defaultPage function
//         site.pageMethods.default(pageID);
//     }

//     // log the page id
//     site.log(pageID);
// };

// a simple sort of "router" for the page scripts.
// most every page needs some sort of initialization; the actions necessary are defined in pages{}.
// since these pages are all individiual static html, each page needs to invoke initialization when it loads.
// pageRouter functions as a simple common function to call with pageID,
// and it will determine what to invoke

const pageSetup = function(pageID) {
    // make globally-available values
    window.pageID = pageID;

    // find if there is a page method, or else use default
    if (typeof pageMethods[pageID] !== "undefined") {
        pageMethods[pageID](pageID);
    } else {
        pageMethods.default(pageID);
    }

    // log the page id
    site.log(pageID);
};

// module.exports = pages;
module.exports = {
    getNextAndPreviousProjects: util.getNextAndPreviousProjects,
    getProjectURL: util.getProjectURL,
    pageSetup: pageSetup,
};
