// *****
// generate and manage portfolio gallery items
// *****

// multiplier used for calculating the timer when cards are hovered/unhovered
const delayMultiplier = 29;

// name of the classed to signify not-hovered, default, or being hovered.
const classDefault = "state-default";
const classNotHovered = "state-nothovered";
const classHovered = "state-hover";

/**
 * internal value that represents there is no previous card.
 * representation of 'false'.
 * using number because 'false' doesnt work with the logic, since a check is done for index #0 in the array
 *  @constant
 *  @default
 */
const noPreviousCardValue = -1;

const portfolioGrid = function () {

    /**
     * variable to store identity of the previous card, if there was one
     * set initial value = to "there is no previous card"
     */
    let previousCard = noPreviousCardValue;

    /**
     * check if there is a timer on the card, and if so, clear it
     * @method
     * @param {DOMelement} card
     * @returns null
     */
    const checkAndClearTimer = (card) => {
        if (card.timerID) {
            clearTimeout(card.timerID);
            card.timerID = null;
        }
    };

    // handle all the cards
    function notHoverCards(startCardIndex, status = "notHover") {
        // if an action is passed in, use that. otherwise default is "notHover"

        // starting will be the index of current card that was hovered over, in machine indexing
        let cardsBackwardsStart = startCardIndex - 1; // index for counting down from the current card.
        let cardsForwardsStart = startCardIndex + 1; // index for counting up from current card. = skip the current card, since index+1 = current in human indexing

        // utility method:
        // check if there is an existing setTimeout timer on the card (from previous activity)
        // and if so, clear it,
        // then set a new one
        //
        // note: "status" value will be taken from the scoped closure of notHoverCards()
        const notHoverTimer = (timerDelay, index) => {
            const card = portfolioCards[index];
            checkAndClearTimer(card);
            const timer = setTimeout(
                () => {
                    cardMethods[status](card);
                },
                timerDelay,
            );
            card.timerID = timer;
        };

        /// call the notHover status action immediately
        // note: "status" value will be taken from the scoped closure of notHoverCards()
        const notHoverImmediate = (index) => {
            const card = portfolioCards[index];
            checkAndClearTimer(card);
            cardMethods[status](card);
        };

        // starting from the initial card index value,
        // go backwards in list of cards and run the notHover process
        const notHoverBackward = async (startIndex) => {
            let timerMultiplier = 0;
            for (let i = startIndex; i > -1; i--) {
                // const timerDelay = delayMultiplier * timerMultiplier++;
                // notHoverTimer(timerDelay, i);
                notHoverImmediate(i);
            }
        };

        // starting from the initial card index value,
        // go forwards in list of cards and run the notHover process
        const notHoverForward = async (startIndex) => {
            let timerMultiplier = 0;
            for (let i = startIndex; i < portfolioCardsLength; i++) {
                // const timerDelay = delayMultiplier * timerMultiplier++;
                // notHoverTimer(timerDelay, i);
                notHoverImmediate(i);
            }
        };

        // routine for not-hovering the other cards by starting
        // with the immediate neighbors and working out from there in both directions, ending
        // at the first card and last card
        //
        // each animation is timed to run after a delay that starts immediately and increases for each card

        // first: optimize for the cards immediately adjacent to current card
        if (cardsBackwardsStart > -1) {
            notHoverImmediate(cardsBackwardsStart);
        }
        if (cardsForwardsStart < portfolioCardsLength) {
            notHoverImmediate(cardsForwardsStart);
        }

        // then: go through remaining,
        // moving both backwards and forwards
        notHoverBackward(cardsBackwardsStart - 1);
        notHoverForward(cardsForwardsStart + 1);

    }

    // object of methods for card to handle actions
    // keys are the "status" of the card
    const cardMethods = {

        // function: handle itself when user hovers – mouseenters - over the card
        // unlike the other cardMethods, this one is called on a browser event
        // enter status: for when card is being hovered over, active
        enter: function (e) {

            // which card has been "entered" and should now become "active"
            const card = e.target;

            // if this card has an existing timer on it, clear that timer
            checkAndClearTimer(card);

            // remove 'nothovered' class, if it is there
            card.classList.remove(classNotHovered);
            card.classList.remove(classDefault);
            // card.classList.add(classHovered);


            // if there is a value for previousCard, then nothover just that one single card.
            // this is for the case of just moving from one card to another,
            // and the entire card set does not have to change.
            // this is most efficient.
            //
            // if there is no value for previousCard (aka false), then
            // handle all the other cards. do this after the task above, so that all the nothovering happens after the hovering.
            if (previousCard !== noPreviousCardValue) {
                cardMethods.notHover(portfolioCards[previousCard]);
            } else {
                notHoverCards(card.cardIndex);
            }

            // lastly, set the previous card value to this current card
            previousCard = card.cardIndex;
        },

        // notHover status: for when card is "disabled", another card is being active
        notHover: function (card) {
            // when nothovering on the card...
            // just add the nothovered class
            card.classList.remove(classDefault);
            // card.classList.remove(classHovered);
            card.classList.add(classNotHovered);
        },

        // function: reset to default state, neither hovered nor 'nothovered'
        // default status: for when card is default state
        default: function (card) {
            // 1. clear timer on the card (which could be an add 'nothovered' function that was set earlier, but which hasnt fired yet due to longer timing
            checkAndClearTimer(card);
            // 2. remove nothovered class
            card.classList.remove(classNotHovered);
            // card.classList.remove(classHovered);
            card.classList.add(classDefault);

        },
    };

    // access an collection of all the portfolio elements
    // then go through each one and attach the dimming style to the images except for the current hovered one.
    // function is called on hover, and 'this' will be the item being hovered over.
    //     var portfolioHoverIn = function (e) {
    //
    //         // var currentTarget = this.getAttribute("data-target");
    //         // var currentTarget = this.dataset.target;
    //
    //
    //         // the event is on the <a .project-link> element, so 'this' will be the anchor element
    //         var targetID = this.id;
    //
    // //         portfolioCards.each (function(i, card) {
    // //             // card = el;
    // //             cardLink = $(".project-link", card)[0];
    // //
    // //             dataTarget = cardLink.getAttribute("data-target");
    // //             if (dataTarget !== currentTarget) {
    // //                 $(card).addClass("nothovered");
    // //             };
    // //         });
    //
    //         if (!previousCard) {
    //
    //             for (var i = 0; i < portfolioCardsLength; i++) {
    //                 card = portfolioCards[i];
    //
    //                 //cardLink = card.querySelector(".project-link");
    //                 cardAnchor = card.getElementsByClassName("project-link")[0];
    //
    //                 // check if the iteration card id = the new hover target
    //                 // AND if card doesnt already contain the nothovered class
    //                 //   - this avoids changing ALL of the cards when moving between cards, and only changes 2: the new & the previous
    //
    //                 if ( cardAnchor.id !== targetID && !card.classList.contains(classNotHover) ) {
    //     //             if ( (cardAnchor.id !== targetID) && card.className.indexOf(classNotHover) ) {
    //
    //                         // add a timetimeout for each change so that they dont all attempt to happen at once...
    //                         var delay = 291 + 69 * (i-1);
    //
    //                         var timer = setTimeout( function(card) {
    //                             card.classList.add(classNotHover);
    //                             card.timerID = null;
    //                         }, delay, card);
    //     //                     card.className += " " + classNotHover;
    //
    //                         // add custom property to the card to store the setTimeout timer ID
    //                         card.timerID = timer;
    //     //                 }
    //                 } else if (cardAnchor.id === targetID) {
    //                     if (card.timerID) {
    //                         clearTimeout(card.timerID);
    //                     }
    //
    //                     // card is pretty much guaranteed to have the nothoverclass already, since the event only fires when changing cards
    //                     // except on the first time
    //                     card.classList.remove(classNotHover);
    //                     previousCard = cardAnchor.id;
    //                 };
    //
    //             };
    //
    //         } else {
    //                 // case: when moving from one card to another
    //                 var oldCard = document.getElementById(previousCard).parentElement;
    //                 if (oldCard.timerID) {
    //                     clearTimeout(oldCard.timerID);
    //                 };
    // //                 oldCard.classList.add(classNotHover);
    //                 setTimeout( function(card) {
    //                     card.classList.add(classNotHover);
    //                     card.timerid = null;
    //                 }, 100, oldCard);
    //
    //                 var newCard = document.getElementById(targetID).parentElement;
    //                 if (newCard.timerID) {
    //                     clearTimeout(newCard.timerID);
    //                 };
    //
    //                 newCard.classList.remove(classNotHover);
    //                 previousCard = targetID;
    //         };
    //     };

    // when leaving the portfolioGrid entirely,
    // go through each card and set them to "default" status.
    // this is called on mouseleave event
    const portfolioGridLeave = function (e) {

        // dont really care about e, the event object...

        // when this fires, the value of previousCard should be "the currently/last active card"
        //
        // start with the previousCard if there is one.
        // if previouscard is "falsey" then this is happening at some odd time in beginning of load, like if the page loads and the cursor is
        // already over a card, and then user moves the cursor. so catch that case.
        if (previousCard > noPreviousCardValue) {
            cardMethods.default(portfolioCards[previousCard]);
        } else {
            previousCard = noPreviousCardValue;
        }

        // now activate default for all the cards
        // starting form the previousCard value.
        notHoverCards(previousCard, "default");

        // reset the previousCard tracking value
        previousCard = noPreviousCardValue;
    };

    // *****
    // card item setup
    //
    function setupPortfolioBehavior() {
        // get all the portfolio cards
        // var items = $(galleryAnchorSelector);
        // var items = document.querySelectorAll(galleryAnchorSelector);
        // var items = document.getElementsByClassName("project-link");

        // const cards = portfolioCards;
        let card;
        let i;

        for (i = 0; i < portfolioCardsLength; i++) {
            // local accesor for convenience
            card = portfolioCards[i];

            // while iterating, add the hover handlers to each card
            // card.cardMethods = cardMethods;
            // card cardIndex: machine indexing
            card.cardIndex = i;

            // add event listeners on each card for mouse-entering (hover-in) and mouse-leaving (hover-out)
            // so that each card can take care of itself

            card.addEventListener("mouseenter", cardMethods.enter, false);
        }

        // one mouseleave event for the entire grid
        document.getElementById("portfolio-grid").addEventListener("mouseleave", portfolioGridLeave, false);
    }

    // *****
    // isotope setup
    //     function setupGalleryLayout(grid, gridItemClass, callback) {
    //         //console.log ("isotope: grid first", grid.children(":first"));
    //         var itemSelectorClass = gridItemClass;  // class name of each portfolio gallery item. no dot.
    //         var itemSizerClass = "portfolio-sizer";
    //
    //         grid.children(":first").addClass(itemSizerClass);
    //
    //         // initialization
    //         var isotopeOptions = {
    //             itemSelector: "." + itemSelectorClass,
    //             "masonry": {
    //                 columnWidth: "." + itemSizerClass
    //             },
    //             percentPosition: true,
    //             initLayout: true
    //         };
    //
    //         grid.isotope (isotopeOptions);
    //
    //         // callback for after images load
    //         grid.ImagesLoaded().always(function() {
    //             // use "layout" because no filtering or sorting needed here
    //             grid.isotope("layout");
    //             if (callback) {
    //                 callback();
    //             };
    //         });
    //     };

    // *****
    // masonry setup

    //     function setupMasonry(grid) {
    //
    //         var itemSelectorClass = "portfolio-item";  // class name of each portfolio gallery item
    //
    //         var itemSizerClass = "portfolio-sizer";
    //
    //         // for masonry settings: add sizer class to first portfolio item in the bunch
    //         // var portfolioTarget = $("#portfolio-grid");  defined above.
    //         grid.children(":first").addClass(itemSizerClass);
    //
    //         // basic masonry initialization
    //         grid.masonry ({
    //             itemSelector: "." + itemSelectorClass,
    //             columnWidth: "." + itemSizerClass,
    //             percentPosition: true,
    //         });
    //
    //         // re-layout after images load
    //         grid.imagesLoaded().always (function() {
    //             grid.masonry("layout");
    //         });
    //
    //     };


    // *****
    // main routine for the module
    // does the initialization,
    // generates the portfolio grid,
    // configures the cards,
    // and sets up the behaviors


    // function main() {
    // get the collection of all the portfolio items & links

    // window.portfolioCards = $(".portfolio-card");
    // window.portfolioCards = document.querySelectorAll(".portfolio-card");

    // declare & define here - outside of the functions where used - in attempt to cache
    // and make access a little faster
    // const portfolioCards = window.portfolioCards;
    // const portfolioCardsLength = window.portfolioCards.length;

    // get all the portfolio cards in the gallery
    const portfolioCards = document.querySelectorAll(".portfolio-card");
    // window.portfolioCards = portfolioCards;

    // cache the length = how many cards in the gallery
    const portfolioCardsLength = portfolioCards.length;

    // 'shadow' object of internal properties for the portfolio cards


    // setupPortfolioBehavior();
};

module.exports = portfolioGrid;
