// **********
// site script
// immediate execution
//
// fun little bit for the dingbat in the primary nav bar
// randomly select one of the available dingbats and insert it into the page
//
// this script loads/runs at page bottom, to make sure it only runs after the dingbat DOM element is loaded

// array of possible glyphs
const dingbats = [
    "<span class='thinking-face'>&#129300;</span>", // thinkin' face
    "<span class='star'>&#9733</span>", // star
    "<span class='circle-star'>&#x272A</span>",  // circled filled star
    "<span class='sun'>&#x2609</span>", // sun ☉
    // "<span class='finger-point'>&#x261B</span>", // filled pointing index finger
    "<span class='heart'>&hearts;</span>",
    "<span class='floral-heart'>&#x2766</span>", // floral heart
    "<span class='snowflake'>&#x2744</span>", // snowflake
    "<span class='spoked-asterisk'>&#x2749</span>", // balloon-spoked asterisk
    "<span class='propeller-asterisk'>&#10059</span>", // Heavy Eight Teardrop-Spoked Propeller Asterisk
    "<span class='sixteen-asterisk'>&#x273A</span>", // sixteen-pointed asterisk
    "<span class='element-of dcenter' title='is an element of the set'>&#8712</span>", // math; Element Of
    "<span class='bang'>&#8253;</span>", // interobang!
    "<span class='bacon dcenter'>&#129363</span>", // bacon
    "<span class='fa fa-hand-o-right'></span>", // &#xf0a4
    "<span class='fa fa-lightbulb-o'></span>",
    "<span class='fa fa-glass'></span>",
    "<span id='coffee'>☕</span>",
    "<span class='donut dcenter'>🍩</span>",
    "<span class='om' title='om'>&#x950;</span>", // om
    "<span class='sharp'>&#9839;</span>", // musical sharp
    "<span class='pencil'>&#10000;</span>", // upper right pencil
    "<span id='hexagram-creative-heaven dcenter' title='the creative hexagram'>䷀</span>", // creative heaven
    "<span class='virgo'>&#9805;</span>", // virgo
    "<span id='squirrel'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 88 88'><path d='M87 34.1c-4.3-15.3-16.1-15-16.1-15-2.2-8-5-6.6-5-6.6a11.8 11.8 0 0 0-3 7.6c-8.1 3.9-7.6 13.5-7.6 13.5-7 0-13.7 5.9-18.1 10.9 16.5-24 3.4-35.6 3.4-35.6-20-20-44.9-1.4-40 7.5 2.8 5.3 18.8-.7 20.5 7 1.2 5.7-3.7 11.7-6.7 15.9C8.4 47.9-3 58.3 2 70c3 7 10.6 12.2 17 15.7 1.4.7 3.5 2.2 5.1 2.2h41.6c7-8.3-10.3-5.8-10.3-5.8a20.9 20.9 0 0 0 5-20.2c11 5 14.7-2 14.7-2 3.8-5.6 5.6-3.2 8-4.4s2-2.5 2-5.3c.2-4.7-9.2 2.2-11.6-.5-5.6-6.2-2.6-7.6-2.6-7.6 9.4 1.5 13.7-1.7 13.7-1.7 0-1.5 2.8-1.5 3-2.4a6 6 0 0 0-.7-3.9z'/></svg></span>",
    "<span class='ankh'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 61 90'><path d='M60.6 34l-26.3 3.2s13.9-8.3 13.9-19.9c0 0 .7-17.3-17.9-17.3S12.4 17.3 12.4 17.3c0 11.7 13.9 19.9 13.9 19.9L0 34v16l26.3-5.9-6 45.9h19.9l-5.9-45.9L60.6 50V34zm-30.3-1.7s-11.4-6.4-11.4-15.6c0-5.3 4.3-10.2 11.9-10.2s11 5 11 10.2c-.1 9.7-11.5 15.6-11.5 15.6z'/></svg></span>",
    "<span class='grass dcenter'><span class='castle'></span></span>",
];

const howManyDingbats = dingbats.length;
const dingBatDOMelement = document.getElementById("dingbat");

const getDingbat = () => {
    const n = Math.floor(Math.random() * howManyDingbats);
    return dingbats[n];
};

const setDingbat = () => {
    dingBatDOMelement.innerHTML = getDingbat();
};

const dingbat = (function() {
    document.addEventListener("DOMContentLoaded", setDingbat, false);
})();
