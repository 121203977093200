// *****
// project module
//
// project: alpine "sierra" redesign icons
// generate a gallery of the icons used in the redesign
//
// *****

// global variables that are not formally defined in the source code
/* globals site, Mustache */

// define iconography list as array
// each entry is
// name: name
// group: the group
// position: the (row, col) position of the icon in the sprite's grid
const sierraIcons = [

// data sources:
    {   group: "Data Source",
        name: "Hive",
        position: {row: 1, col: 1},
    },
//         {   name: "Hawq",
//             group: "Data Source",
//             position: {row: 1, col: 2},
//             disable: true,
//         },
    {   group: "Data Source",
        name: "Greenplum",
        position: {row: 1, col: 3},
    },
//         {   name: "Oracle",
//             group: "Data Source",
//             position: {row: 1, col: 4},
//             disable: true,
//         },
    {   group: "Data Source",
        name: "Postgres",
        position: {row: 1, col: 5},
    },
    {   group: "Data Source",
        name: "JDBC",
        position: {row: 1, col: 6},
        //  disable: true,
    },
    {   group: "Data Source",
        name: "Hadoop",
        position: {row: 1, col: 7},
    },
//         {   group: "Data Source",
//             name: "Test",
//             position: {row: 1, col: 8},
//             disable: true,
//         },

// workfiles:
// touchpoints icon
    {   group: "Document",
        name: "Touchpoint",
        position: {row: 2, col: 1},
    },

    // other workfiles documents
    {   group: "Document",
        name: "Alpine Workflow",
        position: {row: 3, col: 1},
    },
    {   group: "Document",
        name: "Python Notebook",
        position: {row: 3, col: 2},
    },
    {   group: "Document",
        name: "CSV",
        position: {row: 3, col: 3},
    },
//         {   group: "Document",
//             name: "Word Doc",
//             position: {row: 3, col: 4},
//         },
//         {   name: "Powerpoint",
//             group: "Document",
//             position: {row: 3, col: 5},
//         },
//         {   group: "Document",
//             name: "JSON",
//             position: {row: 3, col: 6},
//             disable: true,
//         },
    {   group: "Document",
        name: "PIG Script",
        position: {row: 3, col: 7},
    },
//         {   group: "Workfile",
//             name: "PMML Model",
//             position: {row: 4, col: 1},
//             disable: true,
//         },
    {   group: "Document",
        name: "PFA Model",
        position: {row: 4, col: 2},
    },
    {   group: "Document",
        name: "Alpine Model",
        position: {row: 4, col: 3},
    },

// Jobs
    {   group: "Job",
        name: "Scheduled Job",
        position: {row: 5, col: 1},
    },
    {   group: "Job",
        name: "Task: Python notebook",
        position: {row: 5, col: 2},
        disable: true,
    },
//         {   group: "Job",
//             name: "Task: Alpine workflow",
//             position: {row: 5, col: 3},
//             disable: true,
//         },
//         {   group: "Job",
//             name: "Task: SQL",
//             position: {row: 5, col: 4},
//             disable: true,
//         },

// workspaces

    {   group: "Workspace",
        name: "Active, public",
        position: {row: 6, col: 1},
    },
    {   group: "Workspace",
        name: "Active, private",
        position: {row: 6, col: 2},
    },
    {   group: "Workspace",
        name: "Archived, public",
        position: {row: 6, col: 3},
    },
//         {   group: "Workspace",
//             name: "Archived, private",
//             position: {row: 6, col: 4},
//             disable: true,
//         },
];


const sierraIconography = (function() {

    // declare template at top for entire object so that it can be accessed in sub routines
    let template;

    // width of the whole background image sprite. in px. @1x scaling
    const image_width = 1050;
    const image_height = 900;

    // scale factor for the source image, if necessary
    const projectImageScale = 0.7;

    // in sprite: each row = 150px / each col = 150px @1x scale
    const offset = 150;

    // the size of the sprite grid used in the image. in px. @1x scale
    const spriteDimension_x = 150;
    const spriteDimension_y = 150;

    // size of container ("window") that will display the sprite. in px
    const displayDimension_x = 120 * projectImageScale;
    const displayDimension_y = 120 * projectImageScale;

//     image_width = image_width * projectImageScale;
//     image_height = image_height * projectImageScale;

    // percentage offsets for each sprite in the src graphic
    // const spriteOffsetPercentage_x = spriteDimension_x / image_width;
    // const spriteOffsetPercentage_y = spriteDimension_y / image_height;

    // percentage offsets for each sprite relative to the display window
    // const windowOffsetPercentage_x = displayDimension_x / image_width;
    // const windowOffsetPercentage_y = displayDimension_y / image_height;

    // ratio of the src image size to the display area
    // const spriteRatio = displayDimension_x / spriteDimension_x;

    const imageAdjusted_width = image_width - (displayDimension_x / projectImageScale);  // still not sure why this is the case
    const imageAdjusted_height = image_height - (displayDimension_y / projectImageScale);  // still not sure why this is the case

    // truncate number to fixed # of digits
    const fixedDecimal = (n) => parseFloat(n).toFixed(5);

	// NOTE: offset calc needs machine-friendly indexing, not human-friendly
    // const offsetPosition = (i) => {
    //     return -(i * offset * projectImageScale);
    // };

    const calculatePercentageDecimal = (a, b) => a / b;

    const offsetPercentage_x = (i) => {
        let offsetValue;
        const indx = i - 1;
        if (indx < 1) {
            offsetValue = 0;
        } else {
            const physicalOffset = spriteDimension_x * indx;  // physical offset of the sprite
            const percentage = calculatePercentageDecimal(physicalOffset, imageAdjusted_width);
            offsetValue = percentage;
        }
        return offsetValue;
    };

    const offsetPercentage_y = (i) => {
        let offsetValue;
        const indx = i - 1;
        if (indx < 1) {
            offsetValue = 0;
        } else {
            const physicalOffset = spriteDimension_y * indx;  // physical offset of the sprite
            const percentage = calculatePercentageDecimal(physicalOffset, imageAdjusted_height);
            offsetValue = percentage;
        }
        return offsetValue;
    };

    function generate() {
        const icons = sierraIcons;

        let collection = "";
        let rendered;
        let xPos;
        let yPos;

        icons.forEach((item) => {
            if (!item.disable) {

                // calculate the decimal percentage value for the offset, truncate the number of digits, and then convert to common % value string
                xPos = (fixedDecimal(offsetPercentage_x(item.position.col))) * 100 + "%";
                yPos = (fixedDecimal(offsetPercentage_y(item.position.row))) * 100 + "%";

                // render the element...
                rendered = Mustache.render(template, {
                    tip: item.group + ": " + item.name,
                    row: item.position.row,
                    col: item.position.col,
                    xOffset: xPos,
                    yOffset: yPos,
                });
                // ...and build collection
                collection += rendered;
            }
        });

        return collection;
    }

    function main() {
        const initTemplate = site.templates.initTemplate;
        const getTarget = site.templates.getTarget;

        // identity of the element where the generated content should be inserted
        const pageTargetID = "sierra-icon-grid";
        // identity of the template for each generated element (inlined within the page code)
        const templateID = "template-iconGallery";

        // intialize the html template
        template = initTemplate(templateID);
        const iconCollection = generate();

        // collection assembled. now append it to the target DOM element
        // target = destination for the rendered content
        const target = getTarget(pageTargetID);
        target.innerHTML = iconCollection;
    }

    return {
        main,
    };

})();

module.exports = sierraIconography;
