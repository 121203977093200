// **********
// site script for site pages
//
// transition header to a different style when scrolling below a certain point

/* globals _ */    // global variables that are not formally defined in the source code

(function scrollBehavior() {

    const handlers = []; // array of all the scroll check handlers that get set up
    // let scrollToggled = false;  // boolean: whether the things have toggled or not from scrolling
    const windowHeight = window.innerHeight;

    const throttleOptions = {
        trailing: true,
        leading: false,
    };

    // cache header element. if it isnt there, use empty set
    const primaryNav = document.getElementById("primaryNav") || {};

    // util function
    // given a transition percentage, calculate where on the page that transition should happen
    //
    // function calculateOffset(t) {
    const calculateOffset = (t) => Math.round(windowHeight * t);

    // util function
    // find total height of a DOM element passed in
    //
    // const heightTotal = (el) => {
    //     let h = el.offsetHeight;
    //     const style = getComputedStyle(el);
    //     h += parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
    //     return h;
    // };

    // util
    // check if the page on load has the class to expand or not
    // if TRUE, it means that the page may not be 'collapsed' by default
    // if FALSE, then it is always collapsed
// * DISABLED
//     function navCheckExpanding(el) {
//         var hasExpand = el.classList.contains("navbar-expand-md") ? true : false;
//         return hasExpand;
//     }

    // *****
    // closure callback
    // @param [handlers] will be array of all the handler methods that have been set up for the page
    // so when called, set up the function to loop through that array and call each one
    // then return that function so that it can be called anytime later
    //
    function runAllHandlers(handlers) {
        const ln = handlers.length;
        let pageY;

        const scrollRun = () => {
            pageY = window.pageYOffset;  // get current y-position. NOTE: forces reflow
            for (let i = 0; i < ln; i++) {
                handlers[i](pageY);
            }
        };

        return scrollRun;
    }

    // VALUES FOR: top navbar opacification
    // calculate pixel position where the header bar should transition
    const nav_transition_threshold = 0.14;  // where on the page the header transition should occur, as decimal % of page height
    const navChangeOffset = calculateOffset(nav_transition_threshold);

    // *****
    // header nav
    // change class on header
    // @param b: boolean if should toggle
    //
    function navOpacity(value) {
        // error handling. make sure the primaryNav is there
        if (primaryNav.classList) {
            if (value) {
                primaryNav.classList.add("scrolled");
            } else {
                primaryNav.classList.remove("scrolled");
            }
        }
    }

// * DISABLED
    // *****
    // header nav
    // 'collapse' change
    //
    // header element already cached (above)
    //
//    var el_site_navbar = document.querySelector(".navbar.fixed-top");
//    var el_site_navbar_classlist = el_site_navbar.classList;
//
//
//     function navToggle(toggle) {
//         // console.log ("navtoggle");
//
//         if (toggle) {
//             //$(".site-navbar .navbar-collapse").collapse("toggle");
//             el_site_navbar.classList.remove("navbar-expand-md");
//
//         } else {
//             // $(".site-navbar .navbar-collapse").collapse("toggle");
//             el_site_navbar.classList.add("navbar-expand-md");
//         }
//     };


// * DISABLED
    // *****
    // change the page content position
    //
    // when the page header/title has been changed because of passing the scroll threshold,
    // then also adjust the margins on the main content so that it maintains vertical position

//     var pageContentStyle = function pageContentStyle(b) {
//
//         if (b) {
//             var marginRule = "margin-top:" + heading_height + "px";
//             // document.getElementById("project-header").classList.add("scrolled");
//             page_main.setAttribute("style", marginRule);
//         } else {
//             // document.getElementById("project-header").classList.remove("scrolled");
//             page_main.removeAttribute("style");
//         }
//
//     };


// * DISABLED
    // *****
    // change the project page header styling
    //
    // on the project pages,
    // when scrolling down,
    // alter the style of the project title & company name
    //
//     function headingStyleChange(b) {
//         if (page_heading) {
//             if (b) {
//
//                 page_heading.classList.add("scrolled");
//                 page_heading.classList.add("fixed-top");
//                 pageContentStyle(b);
//             } else {
//
//                 page_heading.classList.remove("scrolled");
//                 page_heading.classList.remove("fixed-top");
//                 pageContentStyle(b);
//             }
//         }
//     };


    // util function
    // given a transition threshold as y-value in pixels,
    // and a function,
    // check to see if current position has crossed the threshold
    // and call the function if so

    const scrollTriggerCheck = function scrollTriggerCheck(changePoint, toggled, changeFunction) {
        let isToggled = toggled;

        // expects argument: pageYOffset
        const scrollCheck = (dY) => {
            if (dY > changePoint && !isToggled) {
                isToggled = true;
                changeFunction(true);
            } else if (dY < changePoint && isToggled) {
                isToggled = false;
                changeFunction(false);
            }
        };
        return scrollCheck;
    };

    // handler for nav visual change
    // const navChangeHandler = scrollTriggerCheck(navChangeOffset, false, navOpacity);
    // handlers.push(navChangeHandler);
    handlers.push(scrollTriggerCheck(navChangeOffset, false, navOpacity));

// * DISABLED
    // handler for nav toggle display of the hamburger
//     if (navCheckExpanding(el_site_navbar)) {
//         var navToggleHandler = scrollTriggerCheck(navToggle_offset, false, navToggle);
//         handlers.push(navToggleHandler);

//     }

// * DISABLED
    // handler for toggling style of the project page heading
    // only set up if the heading is on the page
//     if (heading_height) {
        //var headingChangeHandler = scrollTriggerCheck(projectHeading_offset, false, headingStyleChange);
        //handlers.push(headingChangeHandler);
//     }



    // ***************************
    // main stuff that sets it all up

    //
    // create run function with all of this page's handlers
    // set up as a closure/callback function because it seemed like the clearest thing to do
    const runAll = runAllHandlers(handlers);

    // kick off ONCE immediately to handle
    // (1) reload, or
    // (2) loading to some arbitrary position in the page other than top
    runAll();

    // set up for ongoing checks
    // throttle the checks so that it doesnt fire constantly
    const scrollCheck = _.throttle(runAll, 220, throttleOptions);

    // set reoccurring event listener on the throttled function
    window.addEventListener("scroll", scrollCheck, false);
})();
