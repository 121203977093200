// **********
// site utility script
//
// just the output of message
// @param {string} m: text to log (eg. the name of the page)

const logMessage = (m) => {
    if (window.console) {
        console.log ("log:", m);
    }
};

module.exports = logMessage;
