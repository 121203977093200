// site portfolio data
//
// master file.
// includes all the fundamental data,
// AND the processing for generated values.

const portfolioSetup = (function() {

    // define portfolio projects
    //
    // id:              some unique id for the project. format: company-project
    // img:             path to the full size image for the project
    // img-small:       path to the thumbnail to display in the portfolio grid
    // img-alt:         alt text for the image (optional)
    // img-style:       inline override or css style additions. applied only to the specific image (optional)
    // company:         ** company is in the project page data
    // project:         project name. ** name is now in the project page data
    // project-date:    ** date is in the project page data
    // tags:            ** tags are in the project page data
    // text-on-hover:   text to display when hovering over the portfolio grid item. populates the title tag (optional)
    // url:             path to the project details content file
    // page:            convenience value. just the project page name, without extension. intended to match the panini {{page}} value when on the page, for cross-referencing.

    // generated vales added in postprocess:
    // index:           index value of the current project
    // next:            index data of the next project
    // previous:        index data of the previous project (in case that wasnt obvious)

    const portfolioData = {
        "alpine-sierra": {
            id: "alpine-sierra",
            "img-small": "alpine/sierra-ide@1x.png",
            "img-alt": "Alpine Chorus Platform Redesign",
            // "project": "*Alpine Chorus Redesign",
            "text-on-hover": "A metamorphosis of the platform with a redesign, unification, and product direction.",
            url: "alpine/sierra.html",
            page: "sierra",
        },
        "alpine-iconography": {
            id: "alpine-iconography",
            "img-small": "alpine/iconography.png",
            "img-alt": "Machine Learning Operator Iconography",
//             "project": "Operator Iconography",
            "text-on-hover": "Redesign the family of machine learning operator icons.",
            url: "alpine/iconography.html",
            page: "iconography",
        },
        "alpine-dashboard": {
            id: "alpine-dashboard",
            "img-small": "alpine/dashboard.png",
            "img-alt": "Alpine Chorus Dashboard",
//             "project": "Dashboard Home Page",
            "text-on-hover": "Reinvention of the home page into a personal dashboard.",
            url: "alpine/dashboardFramework.html",
            page: "dashboardFramework",
        },
        "alpine-worklet": {
            id: "alpine-worklet",
            "img-small": "alpine/worklet-run@1.5x.png",
            "img-alt": "Touchpoints Analytics Application",
//             "project": "Touchpoints",
            type: "Web application",
            "text-on-hover": "Product design of new application providing self-service predictive analytics",
            url: "alpine/worklets.html",
            page: "worklets",
        },
//         "alpine-rolls": {
//             disable: true,
//             id: "alpine-rolls",
//             img: "alpine/roles-groups-permissions@2x.png",
//             "img-small": "alpine/roles-groups-permissions@2x.png",
//             "img-alt": "Roles, Groups, and Permissions",
// //             "project": "Roles and Permissions",
//             "text-on-hover": "Design of enterprise roles, groups, and permissions for Chorus.",
//             url: "alpine/rollsGrapesPersimmons.html",
//             page: "rollsGrapesPersimmons",
//         },
//         "alpine-dataops": {
//             disable: true,
//             id: "alpine-dataops",
//             "img-small": "alpine/data-ops@2x.png",
//             "img-alt": "Dataops",
// //             "img-style": "",
// //             "project": "Data Ops",
//             "text-on-hover": "See details of Data Ops",
//             url: "alpine/dataOps.html",
//             page: "dataOps",
//         },
        "alpine-notebooks": {
            id: "alpine-notebooks",
            "img-small": "alpine/notebook@1x.png",
            "img-alt": "Notebook Integration",
//             "project": "Notebook Integration",
            "text-on-hover": "Integration of Jupyter interactive notebooks into Alpine Chorus.",
            url: "alpine/notebooks.html",
            page: "notebooks",
        },
        "alpine-autosave": {
            id: "alpine-autosave",
            "img-small": "alpine/autosaving@2x.png",
            "img-alt": "Workflow Autosave",
//             "project": "Workflow Autosave",
            "text-on-hover": "Integrated set of features designed to add workflow document autosaving.",
            url: "alpine/autosave.html",
            page: "autosave",
        },
        "ca-executiveInsight": {
            id: "ca-executiveInsight",
            "img-small": "ca/ei-v2-dashboard.png",
            "img-alt": "Executive Insight",
//             "project": "Executive Insight",
            "text-on-hover": "Product conception and design of new enterprise application to build relationships between IT metrics and business performance.",
            url: "ca/executiveInsight.html",
            page: "executiveInsight",
        },
//         "ca-apmMobile": {
//             disable: true,
//             id: "ca-apmMobile",
//             "img-small": "ca/durin-3-notifications.png",
//             "img-alt": "APM Mobile Application",
// //             "project": "APM Mobile Application",
//             "text-on-hover": "Product design, creative direction, and interaction architecture of new APM on Mobile experience.",
//             url: "ca/apmMobile.html",
//             page: "apmMobile"
//         },
//         "ca-mobile": {
//             disable: true,
//             id: "ca-mobile",
//             "img-small": "ca/mobileStandards.png",
//             "img-alt": "Mobile Standards",
// //             "project": "Enterprise Mobile Standards and Guidelines",
//             "text-on-hover": "See details",
//             url: "ca/mobileStandards.html",
//             page: "mobileStandards",
//         },
        "ca-gmRedesign": {
            id: "ca-gmRedesign",
            "img-small": "ca/gm-step@2x.png",
            "img-alt": "GovernanceMinder Redesign",
//             "project": "Governance&shy;Minder Redesign",
            "text-on-hover": "Design direction and contributing user experience work to redesign GovernanceMinder.",
            url: "ca/gmRedesign.html",
            page: "gmRedesign",
        },

        "ca-gmMobile": {
            id: "ca-gmMobile",
            "img-small": "ca/gm-mobile@2x.png",
            "img-alt": "GovernanceMinder Mobile Concept",
//             "project": "Governance&shy;Minder Mobile Application",
            "text-on-hover": "Conceptual design and design direction to create a mobile experience for GovernanceMinder.",
            url: "ca/gmMobile.html",
            page: "gmMobile",
        },
        "evernote-sharing": {
            id: "evernote-sharing",
            "img-small": "evernote/notebook-share-more@1x.png",
            "img-alt": "Evernote Notebook Sharing",
            project: "Share Notebooks",
            "text-on-hover": "Product design to add sharing features for the online service.",
            url: "evernote/notebookSharing.html",
            page: "notebookSharing",
        },
        "paypal-merchantManager": {
            id: "paypal-merchantManager",
            "img-small": "paypal/paypal-home-mod@1x.png",
            "img-alt": "Paypal Merchant Manager Redesign",
            // project: "Merchant Manager Redesign",
            "text-on-hover": "User Experience and interaction design for evolution of the Merchant Manager platform.",
            url: "paypal/merchantManager.html",
            page: "merchantManager",
        },
//         "collabnet-rubicon": {
//             disable: true,
//             id: "collabnet-rubicon",
//             "img-small": "collabnet/rubicon-owner-homepage@50-optimized.png",
//             "img-alt": "Collabnet Redesign Vision",
// //             "project": "Next-Gen Product Design",
//             "text-on-hover": "Design-driven redesign of the Collabnet Platform to dramatically change the user experience.",
//             url: "collabnet/rubicon.html",
//             page: "rubicon",
//         },
        "collabnet-ptRedesign": {
            id: "collabnet-ptRedesign",
            "img-small": "collabnet/pt-redo@2x.png",
            "img-alt": "Project Tracker Redesign",
            //"img-style": "transform:scale(1.3) translateX(5%) translateY(5%);",
//             "project": "Project Tracker Redesign",
            "text-on-hover": "User-centered redesign of the issue tracking application.",
            url: "collabnet/ptRedesign.html",
            page: "ptRedesign",
        },
        "collabnet-projectMetrics": {
            id: "collabnet-projectMetrics",
            "img-small": "collabnet/metrics-landing@1x.png",
            "img-alt": "Project Metrics",
//             "project": "Project Metrics",
            "text-on-hover": "Creation of a new reporting and project analytics tool in the Collabnet platform.",
            url: "collabnet/projectMetrics.html",
            page: "projectMetrics",
        },
        "collabnet-tasks": {
            id: "collabnet-tasks",
            "img-small": "collabnet/taskmanagement@1x.png",
            "img-alt": "Activity & Task Management",
            // "project": "Activity & Task Management",
            "text-on-hover": "Design to add task management and reporting.",
            url: "collabnet/taskManagement.html",
            page: "taskManagement",
        },
        // "collabnet-openCollabnet": {
        //     disable: true,
        //     id: "collabnet-openCollabnet",
        //     "img-small": "collabnet/opencollabnet_home.png",
        //     "img-alt": "openCollabnet Community site",
        //     // "project": "Collabnet User Community",
        //     "text-on-hover": "Design of social community site for Collabnet users.",
        //     url: "collabnet/openCollabnet.html",
        //     page: "openCollabnet"
        // },
        "verso-ingram": {
            id: "verso-ingram",
            "img-small": "verso/ingram_flows.png",
            "img-alt": "Imgram Micro E-commerce Shopping Flow",
//             "project": "Ingram Micro: E-commerce System",
            "text-on-hover": "Complete redesign of the Ingram business-to-business e-commerce marketplace.",
            url: "verso/ingram.html",
            page: "ingram",
        },
    };

    // **********
    // processing of the project data array.
    // because some values can be generated instead of hard coded into the data file,
    // allowing some dynamic values at runtime.
    // really, this is not necessary for a static site, but was a sort of exercise.
    // also necessary since the active/inactive project state might change.



    // calcualte project index# for "previous" and "next" project,
    // in a circular liner flow.
    // previous of first project is the last project; next of last project is first project.
    const calculateNextPrevious = (current, total) => {
        return {
            next: current + 1 > total ? 0 : current + 1,
            previous: current - 1 < 0 ? total : current - 1,
        };
    };

    // simple calculation of next & previous project
    // next =      n+1
    // previous =  n-1
    // with wraparound
    // @param {object} projects: object array of the projects
    function generateNeighbors(projects) {
        // declaring these variables here in order to micro-optimize: declaring them once
        // to try to get slightly faster execution, instead of declaring them each time the f() is called
        let aProject, neighbor;

        const keys = Object.keys(projects);

        // create a parallel array of just the active project keys
        const active = [];
        const ln = keys.length;
        let key;
        for (let i = 0; i < ln; i++) {
            key = keys[i];
            if (!projects[key].disable) {
                active.push(key);
            }
        }

        const activeLength = active.length - 1;

        active.forEach((k, i) => {
            aProject = projects[k];

            neighbor = calculateNextPrevious(i, activeLength);
            aProject.index = i;  // the position of the current project
            aProject.next = {
                id: active[neighbor.next],
            };
            aProject.previous = {
                id: active[neighbor.previous],
            };
        });
    }

    // **********

    generateNeighbors(portfolioData);
    return portfolioData;
})();

module.exports = portfolioSetup;
