// **********
// site script
//
// initialize the tinkerer popup
// utilize the BS4 popover feature
// - custom popover code template in order to include custom .class
// - requires the content to be passed with the function

// expects:
// #popover-tinkerer: id of html content to be displayed in the popupTemplate
// #popover-template: id of html template for the tinkerer popover

module.exports = (function () {
    // var popoverOffset = "-35px,-20px";  // offset not working right now, and flip cannot be disengaged

    const template = document.getElementById("template-tinkerer").innerHTML;
    const content = document.getElementById("content-tinkerer").innerHTML;

    const main = () => {
        $("#tinkererPopover").popover({
            container: "body",
            html: true,
            trigger: "hover",
            title: "",
            placement: "top",
            flip: { enabled: false },
            template: template,
            // offset: popoverOffset,
            content: content,
        });
    };

    // public
    return {
        main,
    };
})();
