// **********
// site utility script
//
// reused functions for using in-page (mustache) templates

/* globals Mustache */    // global variables that are not formally defined in the source code

const templateUtil = (function () {

    // get the template
    // @param {string} ID: ID value of the template fragment, without the "#"
    const initTemplate = (ID) => {
        const template = document.getElementById(ID).innerHTML;
        // optimize for usage
        Mustache.parse(template);
        return template;
    };

    // get the target destination
    // @param {string} ID: ID value of the target html element
    const getTarget = (ID) => document.getElementById(ID);

    return {
        initTemplate: initTemplate,
        getTarget: getTarget,
    };

})();

module.exports = templateUtil;
