// **********
// site script for pages
//
// for sticky table of contents:
// get the project's table of contents nav position on page loading,
// and set it's top to that position so that it doesnt move when scrolling
//
// all this 'hacky' work because the size of the head can vary from page to page,
// and the position of the element will vary from page to page
// and the desire is to have the TOC stay in a fixed-like position when scrolling the content
//

(function pageNavPosition() {

    // ID of the table of contents element in the page
    const tableOfContentsElement = "tableOfContents";

    // function getElementTop(el) {
    const getElementTop = (el) => {
        const elPos = el.getBoundingClientRect().top;
        const windowPos = window.pageYOffset;

        // let position;

        // check if at top of page, or if scrolled down further.
        // if down further, then need to calculate the elements's messy offset position
        // if (windowPos === 0) {
        //     position = elPos;
        // } else {
        //     position = windowPos + elPos;
        // }
        const position = windowPos + elPos;
        return position;
    };

    const setTOCPosition = () => {
        // get the HTML element if it is there
        const pageTOC = document.getElementById(tableOfContentsElement);
        // if there, then
        // set the element's style for 'top' = the position on page load
        if (pageTOC) {
            pageTOC.style.top = getElementTop(pageTOC) + "px";
        }
    };

    if (document.readyState === "loading") {
        // load hasn't finished, yet
        // so DOMContentLoaded event will happen in future
        document.addEventListener("DOMContentLoaded", setTOCPosition, false);
    } else {
        // DOMContentLoaded has already fired, so run immediately
        setTOCPosition();
    }

})();
