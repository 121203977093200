// site script: main entry point
// - general site javascript initialization and misc
// - defines globals,
// - immediate execution functions,
// - and functions
// that will be made available via the 'site." namespace at browser-side, via the exports

// make specific lodash functions available globally
const _ = window._ || {};
window._ = _;

_.throttle = require("lodash.throttle");

// SidePanelCollapse source
// sidepanel made available as global
window.SidePanelCollapse = require("SidePanelCollapse");

// module: legacy utilities for pages. mostly the scroll transition handler
require("site-utils");

// module: transition the primarynavbar on scroll
require("site-scrollHeaderBar");

// script for back to top button
// modules/
// require("site-backtotop");

// module: image defer function
require("site-image-defer");

// module: altering carousel behavior to have an 'attract' animation before any interaction
// require("site-carousel-visibility");

// module: handler for randomizing the graphic in header nav
require("site-dingbat");

// module: keyboard navigation for site pages
require("site-keynavigation");

// module: handler for position of the 'table of contents'
require("page-projectNav");


// module: content special behavior
require("page-anchor-replace");

// general site data values
const siteClientSideData = require("siteClientSideData");

// custom event tracking & analytics functions
const analytics = require("site-eventTracking");

// collection of scripts and handlers specific to each page, keyed to page ID
// pages/
const pageMethods = require("pages");

// simple message logger
const logger = require("site-pageLog");

// module: for supporting the use of dynamic moustache-based client side template generation
const templateUtils = require("site-templateUtil");

// expose anything necessarty to expose for use in the page(s)
module.exports = {
    siteClientSideData: siteClientSideData,
    pageMethods: pageMethods,
    log: logger,
    templates: templateUtils,
    analytics: analytics,
};
