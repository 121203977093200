// **********
// site util functions
//

//
// add easeInOutCubic as new jquery easing function for animation/scrolling
//
// transition-timing-function: cubic-bezier(0.690, 0.030, 0.355, 1.000); /* custom */

(function($) {

    $.extend($.easing, {
        easeInOutCubic: (x) => {
            return x < 0.5 ? 4 * x * x * x : (x-1) * (2 * x-2) * (2 * x-2) + 1;
        }

    });
})(jQuery);


//
// helper function outerFind
//

// (function($) {
//     $.fn.outerFind = function(a) {
//         return this.find(a).addBack(a);
//     };
// })(jQuery);


/* jshint ignore:start */
/* eslint ignore:start */

(function ($) {

    //
    // an old version of debounced resize
    // wont process until timeoutDelay passes.
    // https://gist.github.com/pkdsleeper/3802022
    //
    (function ($, c) {
        const timeoutDelay = 242;

        // this is a setup function, that returns a curried function.
        // TODO: DEV:: not sure the $ is needed to pass, as jquery is already global...
        const e = function($, c, e) {
            var d;

            return function () {
                const context = this;
                let args = arguments;
                // console.log ("return function", args);  // todo
                d ? clearTimeout(d) : e && $.apply(context, args);

                d = setTimeout(function() {
                    e || $.apply(context, args);
                    d = null;
                }, c || timeoutDelay);
            };

        };
        jQuery.fn[c] = function($) {
            return $ ? this.on("resize", e($)) : this.trigger(c);
        };
    })(jQuery, "smartresize");


    $(function() {

        function d() {
            $(this).css("height", 9 * $(this).parent().width() / 16);
        }

        let c;
        let e;
        let l = 0;
        let f = null;
        let m = true;

        // $(window).scroll(function() {
        //     console.error ("the on scroll");
        //     e && clearTimeout(e);
        //     let b = $(window).scrollTop();
        //     let c = b <= l || m;

        //     l = b;
        // });

        $(window).on( "scroll", function() {
            e && clearTimeout(e);
            let b = $(window).scrollTop();
            let c = b <= l || m;
            l = b;
        });


        $(window).resize().scroll();
        // $(window).on ("resize", )

        // smoothscroll handler
        // console.error ("************** the jquery smoothscroll ******");

        const scrollDuration = 690;  // total scroll time
        let $root = $("html, body");  // cache for the scroll routine

        // added functions to update the url for anchor links.
        // adapted from https://css-tricks.com/smooth-scrolling-accessibility/

        // utility function for location path filtering
        // function filterPath(string) {
        //     return string
        //         .replace(/^\//, "")
        //         .replace(/(index|default).[a-zA-Z]{3,4}$/, "")
        //         .replace(/\/$/, "");
        // }

        // const filterPath = (string) => {
        //     return string
        //         .replace(/^\//, "")
        //         .replace(/(index|default).[a-zA-Z]{3,4}$/, "")
        //         .replace(/\/$/, "");
        // }

        // onclick handler for any links that are not 'anchors'
        $('a[href*="#"]')
            .not('[href="#"]')
            // .click(function(e) {
            .on ( "click", function(e) {

            // let locationPath = filterPath(location.pathname);

            // console.error ("************** the jquery smoothscroll: href ******");

            // use currentTarget, not e.target.
            // this allows any in-page href link to smoothscroll,
            // because e.target will be the DOM element clicked (e.g. the svg image), while the click event can be on a wrapping <a> element
            // more info: http://stackoverflow.com/a/21667010
            const currentTarget = e.currentTarget;

            try {
                let c = currentTarget.className;
                do {
                    const targetHash = currentTarget.hash;

                    // check: is the click target a #? if so, it is a link within the page.
                    if (targetHash) {

                        // TODO: DEV: maybe create a different new class name that is used just for this check?
                        // check that link isnt part of some tool like
                        // (1) the carousel
                        // by looking at the class of the clicked target.
                        // tediously, this will have to be done for each tool to the site that uses href="#" to function

                        if (c.indexOf("carousel") > -1 ) {
                            break;
                        }

                        // TODO
                        // appears to use the bitwise OR to assign d
                        // need to figure out just what this does, if it is necessary
                        // and what g.test is????
                        // javascript: test() method tests for a match in a string with a regex pattern
                        let d = /#bottom|#top/g.test(targetHash);

                        // if d = true, scroll on the body
                        // if d = false, scroll on the targetHash

                        $(d ? "body" : targetHash).each(function(x) {
                            e.preventDefault();

                            const scrollPosition = $(targetHash).offset().top;
                            const $target = $(targetHash);

                            // use jQuery animate() for smooth scroll
                            $root.stop().animate ({
                                scrollTop: scrollPosition
                            }, scrollDuration, "easeInOutCubic")
                            .promise()
                            .then(function adjustTargetFocus() {
                                // alter the target focus when animation is done
                                location.hash = targetHash;
                                $target.focus();
                                if ($target.is(":focus")) { // checking if the target was focused
                                    return false;
                                } else {
                                    $target.attr("tabindex", "-1"); // add tabindex for elements not focusable
                                    $target.focus(); // set focus
                                };
                            });
                        });
                    }
                }
                while (c = c.parentNode);
            } catch (e) {
                console.log ("error in smoothscroll: " + e.message + ":" + e.stack);
            }
        });

    });

})(jQuery);

/* jshint ignore:end */
