// **********
// site utility script
//
// add an custom event listener to elements that I want to be tracked in site analytics
// e.g. anchor links

//
// and push that event to the analytics handler for tracking

// <!-- <a href="#" onclick="javascript:_paq.push(['trackEvent', 'eventCategoryName', 'eventActionName']);"> -->
// piwik/matomo api:
// trackEvent(category, action, [name], [value])
// https://developer.matomo.org/api-reference/tracking-javascript

//   category =
//   action = the event name
//   name    = given name of the element on which the event action occurred

// declare _analytics as global reference to the analytics library.
// actual value is set later, in trackEventSetup, after page load event when the library will have loaded
let _analytics;

// IE polyfill
// for the element.closest() DOM method
// closest() is used in handleTrackingEvent()
// if (!Element.prototype.matches) Element.prototype.matches = Element.prototype.msMatchesSelector;
// if (!Element.prototype.closest) Element.prototype.closest = function(selector) {
//     var el = this;
//     while (el) {
//         if (el.matches(selector)) {
//             return el;
//         }
//         el = el.parentElement;
//     }
// };

// model for event mapping from native DOM events to human-friendly name = how the event should be named in the analytics tracking
// "DOM event"  -->  "event name in analytics"
// click        -->  click
// mouseenter   -->  hover
// mouseover    -->  hover
// shown        -->  view
const eventThesaurus = {
    "click": "click",
    "mouseenter": "hover",
    "mouseover": "hover",
    "shown": "view",
    "view": "view",
};

// get the human friendly event name from the 'thesaurus' list
const getEventCanonical = (ev) => {

    if (ev === undefined) {
        ev = "";
    }
    // the event is in the list, so get value
    // if the event isnt in the list, then just use it
    const name = eventThesaurus[ev] !== undefined ? eventThesaurus[ev] : ev;
    return name;
};

// function:
// push an event to the analytics handler so that the event is tracked
// use this when the event has already been processed
function handleTrackingEvent(category, action, name, value) {
    const actionCanonical = getEventCanonical(action);
    if (_analytics !== undefined && _analytics) {
        _analytics.push(["trackEvent", category, actionCanonical, name, value]);
        // console.log ("[analytics event]:", category, ":", actionCanonical,":", name, ":", value);  // DEV
    } else {
        // if disabled...
        console.log ("[analytics disabled]:", category, ":", actionCanonical,":", name);
    }
}


// function:
// handle an event when it occurs

const trackEvent = function(e) {

    // get event type. eg. click, mouseenter, etc.
    const eventType = e.type;

    let el = e.target;
    // try to get the data-track-interaction attribute on the event element
    // will be null if that attribute is not there
    const dataFlag = el.dataset.trackInteraction;

    // if the data-track-interaction attribute exists, then dont need to do anything else.
    // if attribute doesnt exist, then find the closest ancestor where the attribute is there
    if (dataFlag === undefined) {
        // magical DOM selector that will return the parent element that matches
        el = el.closest("[data-track-interaction]");
    }

    const { dataset } = el;

    // get the element's "data-content-category" attribute value
    const category = dataset.contentCategory;

    // get the element's "data-content-name" attribute value
    const name = (dataset.contentName) ? dataset.contentName : el.id;
    // if there is no "data-content-name" attribute value, then use the element's id value
//     if (name === undefined) {
//         name = el.id;
//     }

    // get the element's "data-content-value" attribute value
    const val = (dataset.contentValue) ? dataset.contentValue : name;

    handleTrackingEvent(category, eventType, name, val);

};


// get all the things that have "data-track-interaction" attribute
// and put an eventListener on them
function trackEventSetup() {
    console.log ("tracking setup...");

    // define value for the analytics library. if analytics package changes, then change the reference
    window._analytics = window._paq || undefined;

    // select everything on the page that is tagged to be tracked for interaction = has the attribute "data-track-interaction"
    const things = document.querySelectorAll("[data-track-interaction]");
    const ln = things.length;

    // console.log ("tracking:", things);

    // loop through the list and add event behaviors(s) on each element
    for (let i = 0; i < ln; i++) {
        const thing = things[i];
        // check if things[i].dataset.trackInteraction is not empty,
        // which equates to --> there are multiple value(s) for the track-interaction attribute.
        // eg. data-track-interaction="mousenter, click"
        // if so, iterate through them and setup event for each of them.

        if (thing.dataset.trackInteraction) {
            const actions = thing.dataset.trackInteraction.split(",");
            // const listLn = list.length;
            // debugger;
            // let ev, n;

            actions.forEach( (action) => {
                // console.warn ("track: ", action, trackEvent);
                thing.addEventListener(action, trackEvent, { passive: true }, false);
            });
        } else {
            // default = click event
            // console.log ("track:", thing);
            thing.addEventListener("click", trackEvent, {passive: true}, false);
        }
    }
}

// **************
// **************
// setup for manual analytics tracking on site

const main = (function () {
    // small delay before running the tracking setup
    // just to try to improve the intial page display before running script
    window.addEventListener("load", () => {
        setTimeout(trackEventSetup, 111);
    }, false);

    //  make public api for the module
    return {
        trackEvent: handleTrackingEvent,
    };

})();

// also export to use methods in the site
module.exports = main;
