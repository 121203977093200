// **********
// site script
// key navigation
//
// watch the arrow keys and navigate through the pages
// immediate execution
//

// only on the project pages, or pages for which there is a previous & next defined:
// left arrow:      previous project
// right arrow:     next project
// h:               go to home
// g:               go to the gallery

/* globals site, pageID */    // global variables that are not formally defined in the source code

(function() {

    const homepage = "/index.html";

    const getPageURL = (path) => site.pageMethods.getProjectURL(path);

    const getNeighbors = (ID) => site.pageMethods.getNextAndPreviousProjects(ID);

    const navigateTO = (url) => {
        window.location.href = url;
    };

    const navigatePrevious = (currentID) => {
        const neighbors = getNeighbors(currentID);
        if (neighbors) {
            const path = neighbors.previous.url;
            const url = getPageURL(path);
            navigateTO(url);
        }
    };

    const navigateNext = (currentID) => {
        const neighbors = getNeighbors(currentID);
        if (neighbors) {
            const path = neighbors.next.url;
            const url = getPageURL(path);
            navigateTO(url);
        }
    };

    const navigateHome = () => {
        navigateTO(homepage);
    };

    const navigateGallery = () => {
        navigateTO(homepage + "#portfolio");
    };

    const keyHandle = (e) => {
        // const key = (window.event) ? event.keyCode : e.keyCode;
        const key = e.keyCode;

        switch (key) {
//             case 27: // esc
//                 // put stuff here to handle the esc key
//                 break;

            case 37: // left arrow
                navigatePrevious(pageID);
                break;

            case 39: // right arrow
                navigateNext(pageID);
                break;

            case 72: // "h"
                navigateHome();
                break;

            case 71: // "g"
                navigateGallery();
                break;

            default:
                break;  // do nothing
        }
    };

    document.addEventListener("keyup", keyHandle, { passive: true }, false);
})();
