// **********
// site script
// scour the page and replace all images that have been marked with class='img-defer'
// with the img src value defined by the elements 'data-defer-src' attribute

(function imgDefer() {
    // const replaceImages = function() {
    const replaceImages = () => {
        // const imgDeferred = document.getElementsByClassName("img-defer");
        const imageDeferred = document.querySelectorAll("[data-defer-src]")
        const ln = imageDeferred.length;
        let i, el, src;
        for (i = 0; i < ln; i++) {
            el = imageDeferred[i];
            src = el.getAttribute("data-defer-src");
            if (src) {
                el.setAttribute("src", src);
            }
        }
    };

    // setup event listener to run when page load is finished
    window.addEventListener("load", replaceImages, false);
})();
