// **********
// site script for content pages
//
// scour the page and get all elements that match for being
// content sections that are targets for the in-page navigation
// then insert an invisible node before that element to be the anchor target for scrolling - with space for the fixed header
//
// inserting the full DOM element allows z-index positioning that fixes the problem of overlap and being unable to select or click things that are obscured by the ::before content
// this inserted element might be easier to run media queries on, vs. just the :before element in css

(function() {

    const anchorReplace = () => {
        let el;

        // 'index' is used as counter for the continually decremented z-index given to each successive heading element
        let index = 0;

        // get the mainColumn html nodeList. there should only be one of these, so this will get the first === the only
        const mainColumn = document.querySelector(".mainColumn");

        if (mainColumn) {
            const theSections = mainColumn.getElementsByTagName("section");
            const ln = theSections.length;

            for (let i = 0; i < ln; i++) {
                const section = theSections[i];
                section.style.zIndex = --index;

                // selector for all h2 and h3 headings
                // except: exclude any h3 with "data-toc-ignore" attribute
                const headings = section.querySelectorAll("h2,h3:not([data-toc-ignore])");
                let index2 = 0;
                headings.forEach((heading) => {
                    heading.style.zIndex = --index2;
                });

            }
        }
    };

    if (document.readyState === "loading") {
        // loading hasn't finished yet
        document.addEventListener("DOMContentLoaded", anchorReplace, false);
    } else {
        // DOMContentLoaded has already fired
        anchorReplace();
    }

})();
